export enum IndentationMaterial {
  FE = 'FE',
  CU = 'CU',
  LI = 'LI',
  MG = 'MG',
  AL = 'AL',
  TI = 'TI',
  PB = 'PB',
  SN = 'SN',
  OTHER = 'OTHER',
}
