<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<div *transloco="let t; read: 'geometricalInformation'">
  <div class="pb-6 pl-4">
    <mat-slide-toggle
      color="primary"
      [formControl]="enabledControl"
      labelPosition="before"
      >{{ t('control') }}</mat-slide-toggle
    >
  </div>
  @if (enabledControl.value) {
    <div class="pb-6">
      @if (!isValidUnit()) {
        <div
          class="p-4 border border-warning bg-warning rounded text-caption text-warning"
        >
          <div>
            {{ t('error.mpa') }}
          </div>
          @if (isInternal$ | ngrxPush) {
            <div>
              {{ t('error.mpa_prelink') }}
              <a class="text-link" [href]="t('error.mpa_href')" target="_blank">
                {{ t('error.mpa_link') }}</a
              >
            </div>
          }
        </div>
      }
      @if (isValidUnit()) {
        <div class="grid grid-cols-1 gap-4 md:!grid-cols-2">
          <mat-form-field
            appearance="outline"
            class="md:w-72"
            (click)="setFocus()"
          >
            <mat-label>{{ t('controls.value') }}</mat-label>
            <input
              matInput
              [formControl]="valueControl"
              type="number"
              readonly
            />
            <mat-error class="text-caption">{{
              getErrorMessage(valueControl.errors)
            }}</mat-error>
          </mat-form-field>
          @if (diameterControl.enabled) {
            <mat-form-field appearance="outline" class="md:w-72">
              <mat-label>{{ t('controls.diameter') }}</mat-label>
              <input
                matInput
                [formControl]="diameterControl"
                type="number"
                step="0.5"
              />
              <mat-error class="text-caption">{{
                getErrorMessage(diameterControl.errors)
              }}</mat-error>
            </mat-form-field>
          }
          @if (diameterBallControl.enabled) {
            <mat-form-field appearance="outline" class="md:w-72">
              <mat-label>{{ t('controls.diameterBall') }}</mat-label>
              <mat-select [formControl]="diameterBallControl">
                @for (entry of diameterBallOptions; track entry) {
                  <mat-option [value]="entry">
                    {{ entry.diameter }} mm / {{ entry.load }} kp
                  </mat-option>
                }
              </mat-select>
              <mat-error class="text-caption">{{
                getErrorMessage(diameterBallControl.errors)
              }}</mat-error>
            </mat-form-field>
          }
          <!--  allow adding of new entries implementation  -->
          @if (loadControl.enabled) {
            <schaeffler-select
              appearance="outline"
              class="md:w-72"
              [label]="t('controls.load')"
              [stringOptions]="loadOptions"
              [control]="loadControl"
              [addEntry]="true"
              (entryAdded)="onEntryAdded($event)"
              [filterFn]="filterFn"
            ></schaeffler-select>
          }
          @if (materialControl.enabled) {
            <mat-form-field appearance="outline" class="md:w-72">
              <mat-label>{{ t('controls.material') }}</mat-label>
              <mat-select [formControl]="materialControl">
                @for (mat of getMaterials(); track mat) {
                  <mat-option [value]="mat">{{
                    t('material.' + mat)
                  }}</mat-option>
                }
              </mat-select>
              <mat-error class="text-caption">{{
                getErrorMessage(materialControl.errors)
              }}</mat-error>
            </mat-form-field>
          }
          @if (thicknessControl.enabled) {
            <mat-form-field appearance="outline" class="md:w-72">
              <mat-label>{{ t('controls.thickness') }}</mat-label>
              <input
                matInput
                [formControl]="thicknessControl"
                type="number"
                step="0.5"
              />
              <mat-error class="text-caption">{{
                getErrorMessage(thicknessControl.errors)
              }}</mat-error>
            </mat-form-field>
          }
        </div>
      }
      @if (!(resultLoading$ | ngrxPush) && (indentationResult$ | ngrxPush)) {
        <ng-container *ngrxLet="indentationResult$ as response">
          @if (response.error) {
            <div
              class="p-4 border border-warning bg-warning rounded text-caption text-warning"
            >
              {{ t('error.' + response.error) }}
            </div>
          }
          <table class="w-full">
            <colgroup>
              <col class="w-1/2" />
              <col class="w-1/4" />
              <col class="w-1/6" />
              <col />
            </colgroup>
            <tr>
              <td colspan="4" class="pb-4 pt-4 text-center text-subtitle-1">
                {{ t('geometry') }}
              </td>
            </tr>
            @for (item of getGeoKeys(); track item) {
              <tr class="border-b border-border">
                <td>{{ t('prop.' + item.name) }}</td>
                <td class="text-center text-h6 font-medium">
                  {{ get(response, item) }}
                </td>
                <td class="text-center">{{ t('unit.' + item.unit) }}</td>
                <td>
                  <button
                    mat-button
                    (click)="
                      onCopyButtonClick(
                        get(response, item),
                        t('unit.' + item.unit)
                      )
                    "
                  >
                    <mat-icon class="!m-0" color="primary"
                      >content_copy</mat-icon
                    >
                  </button>
                </td>
              </tr>
            }
            @if (hasCorrectedKeys()) {
              <tr>
                <td colspan="4" class="pb-4 pt-8 text-center text-subtitle-1">
                  {{ t('corrections') }}
                </td>
              </tr>
              @for (item of getCorrectedKeys(); track item) {
                <tr class="border-b border-border">
                  <td>{{ t('prop.' + item.name) }}</td>
                  <td class="text-center text-h6 font-medium">
                    {{ get(response, item) }}
                  </td>
                  <td class="text-center">
                    {{ t('unit.' + item.unit) }}
                  </td>
                  <td>
                    <button
                      mat-button
                      (click)="
                        onCopyButtonClick(
                          get(response, item),
                          t('unit.' + item.unit)
                        )
                      "
                    >
                      <mat-icon class="!m-0" color="primary"
                        >content_copy</mat-icon
                      >
                    </button>
                  </td>
                </tr>
              }
            }
            @if (hasOther()) {
              <tr>
                <td colspan="4" class="pb-4 pt-8 text-center text-subtitle-1">
                  {{ t('others') }}
                </td>
              </tr>
              @for (item of getOther(); track item) {
                <tr class="border-b border-border">
                  <td>{{ t('prop.' + item.name) }}</td>
                  <td class="text-center text-h6 font-medium">
                    {{ get(response, item) }}
                  </td>
                  <td class="text-center">{{ t('unit.' + item.unit) }}</td>
                  <td>
                    <button
                      mat-button
                      (click)="
                        onCopyButtonClick(
                          get(response, item),
                          t('unit.' + item.unit)
                        )
                      "
                    >
                      <mat-icon class="!m-0" color="primary"
                        >content_copy</mat-icon
                      >
                    </button>
                  </td>
                </tr>
              }
            }
          </table>
        </ng-container>
      } @else {
        @if (resultLoading$ | ngrxPush) {
          <div
            class="mt-12 flex w-full flex-1 flex-col place-items-center justify-center"
          >
            <mat-spinner></mat-spinner>
          </div>
        }
      }
    </div>
  }
</div>
