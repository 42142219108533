<div class="w-auto">
  @for (group of linkGroups; track group) {
    <div class="text-caption font-medium text-medium-emphasis">
      {{ group.title }}
    </div>
    <ul class="pb-8">
      @for (link of group.links; track link) {
        <li class="mt-3">
          <a class="text-caption text-link hover:underline" [href]="link.uri"
            >{{ link.text }}
          </a>
        </li>
      }
    </ul>
  }
</div>
