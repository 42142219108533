<div class="mb-0.5 w-full">
  <div class="flex flex-1 flex-row items-center gap-4">
    <div class="flex flex-1 flex-row">
      <mat-form-field
        appearance="outline"
        class="pointer-events-none flex w-44 flex-1"
      >
        @if (label) {
          <mat-label>{{ label }}</mat-label>
        }
        <input
          matInput
          readonly
          [value]="value ? transformedValue : warning"
          class="!text-center !text-h6"
        />
      </mat-form-field>
      <div
        class="z-10 mb-6 -ml-1 flex w-[74px] rounded-l-none rounded-r bg-primary"
      >
        <div
          class="m-auto cursor-default text-h6 text-high-emphasis-dark-bg"
          [matTooltip]="tooltip"
        >
          {{ unit }}
        </div>
      </div>
    </div>
    <div class="h-full pb-4">
      <button
        mat-icon-button
        (click)="onCopyButtonClick()"
        [disabled]="!!warning"
      >
        <mat-icon
          [class]="warning ? 'text-low-emphasis' : 'text-medium-emphasis'"
          >content_copy</mat-icon
        >
      </button>
    </div>
  </div>
</div>
