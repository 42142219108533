<div class="flex h-full w-full flex-col" *transloco="let t; read: ''">
  <div class="mt-5 w-full flex-1 overflow-y-auto sm:mt-[50px]">
    <div class="flex flex-col items-center">
      <div class="flex justify-end items-baseline sm:w-[500px] md:w-[680px]">
        {{ t('moreInformationLinkQuestion') }}
        <a
          mat-button
          color="primary"
          class="ml-4"
          (click)="trackEvent('click_learnmore')"
          routerLink="/learn-more"
          >{{ t('learnmore.title') }}</a
        >
      </div>
    </div>
    <div class="flex flex-col overflow-y-auto p-4">
      @if (units$ | ngrxPush) {
        <div
          class="flex flex-col rounded md:mx-auto md:border md:border-border md:p-6 bg-surface"
        >
          <div class="flex w-full flex-row">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-col">
                <mat-form-field appearance="outline" class="md:max-w-[592px]">
                  <mat-label>{{ t('conversionTable') }}</mat-label>
                  <mat-select
                    [formControl]="inputTable"
                    (selectionChange)="trackSelectedConversion($event)"
                  >
                    @for (
                      table of tables$ | ngrxPush;
                      track trackByFn($index, table)
                    ) {
                      <mat-option [value]="table"
                        >{{ t('normLong.' + table) }}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex flex-col gap-4 md:flex-row">
                <mat-form-field appearance="outline" class="md:w-72">
                  <mat-label>{{ t('value') }}</mat-label>
                  <input
                    #defaultInputValue
                    matInput
                    [formControl]="inputValue"
                    type="number"
                    [step]="step"
                  />
                  @if (inputValue.invalid) {
                    <mat-error class="text-caption"
                      >{{ t('negativeValueError') }}
                    </mat-error>
                  }
                </mat-form-field>
                <mat-form-field appearance="outline" class="md:w-72">
                  <mat-label>{{ t('from') }}</mat-label>
                  <mat-select
                    [formControl]="inputUnit"
                    [matTooltip]="getTooltip(inputUnit.value)"
                  >
                    @for (
                      unit of units$ | ngrxPush;
                      track trackByFn($index, unit)
                    ) {
                      <mat-option
                        [value]="unit"
                        [disabled]="!(enabled$ | ngrxPush)?.includes(unit)"
                        [matTooltip]="getTooltip(unit)"
                        >{{
                          t('unit.' + unit, { hint: ' (' + t('uts') + ')' })
                        }}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              @for (inputGroup of additionalInputs.controls; track inputGroup) {
                <div class="flex flex-1 flex-col gap-4 md:flex-row">
                  <mat-form-field appearance="outline" class="md:w-72">
                    <mat-label>{{ t('value') }}</mat-label>
                    <input
                      matInput
                      [formControl]="inputGroup.get('0')"
                      [step]="step"
                      type="number"
                    />
                    @if (inputGroup.get('0')) {
                      <mat-error class="text-caption"
                        >{{ t('negativeValueError') }}
                      </mat-error>
                    }
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="md:w-72">
                    <mat-label>{{ t('value') }}</mat-label>
                    <input
                      matInput
                      [formControl]="inputGroup.get('1')"
                      [step]="step"
                      type="number"
                    />
                    @if (inputGroup.get('1')) {
                      <mat-error class="text-caption"
                        >{{ t('negativeValueError') }}
                      </mat-error>
                    }
                  </mat-form-field>
                </div>
              }
            </div>
            <div class="mb-9 flex flex-col justify-end pl-4">
              <button mat-mini-fab color="primary" (click)="onAddButtonClick()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <div class="flex flex-col pb-4">
            <!-- MULTI VALUE CALCULATION -->
            @if (multipleValues$ | ngrxPush) {
              <div>
                <div
                  class="my-9 flex flex-row justify-center text-center text-subtitle-1"
                >
                  {{ t('statisticalEvaluation') }}
                </div>
                <div class="flex flex-col gap-8 md:flex-row">
                  <div class="flex flex-1">
                    <hc-copy-input
                      class="w-full"
                      [value]="average$ | ngrxPush"
                      [unit]="t('unit.' + inputUnit.value)"
                      [label]="t('averageValue')"
                      [tooltip]="getTooltip(inputUnit.value)"
                      [precision]="getPrecision(inputUnit.value)"
                    ></hc-copy-input>
                  </div>
                  <div class="flex flex-1">
                    <hc-copy-input
                      class="w-full"
                      [value]="standardDeviation$ | ngrxPush"
                      [unit]="t('unit.' + inputUnit.value)"
                      [label]="t('standardDeviation')"
                      [tooltip]="getTooltip(inputUnit.value)"
                      [precision]="1"
                    ></hc-copy-input>
                  </div>
                </div>
                @if (
                  (conversionResult$ | ngrxPush) &&
                  (conversionResult$ | ngrxPush).deviationWarning
                ) {
                  <div class="my-4 max-w-fit">
                    <div
                      class="flex min-w-0 max-w-[648px] flex-row items-center gap-4 rounded border border-warning bg-sunny-yellow bg-opacity-10 p-3"
                    >
                      <mat-icon class="text-warning">warning_amber</mat-icon>
                      <div
                        class="flex-shrink text-caption font-normal text-high-emphasis"
                      >
                        {{ t('deviationWarning') }}
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
            <!-- RESULT -->
            @if (
              !(resultLoading$ | ngrxPush) && (conversionResult$ | ngrxPush)
            ) {
              <div>
                <div
                  class="mb-8 mt-4 flex flex-row justify-center text-center text-subtitle-1"
                >
                  {{
                    (multipleValues$ | ngrxPush)
                      ? t('convertedAfterMultiple', {
                          norm: t('norm.' + inputTable.value)
                        })
                      : t('convertedAfter', {
                          norm: t('norm.' + inputTable.value)
                        })
                  }}
                </div>
                <div class="grid grid-cols-1 gap-x-8 md:grid-cols-2">
                  @for (
                    result of (conversionResult$ | ngrxPush).converted;
                    track result
                  ) {
                    <div class="col-span-1">
                      <hc-copy-input
                        class="w-full"
                        [value]="result.value"
                        [warning]="
                          (enabled$ | ngrxPush)?.includes(result.unit)
                            ? result.warning
                            : t('notInTable')
                        "
                        [unit]="t('unit.' + result.unit)"
                        [tooltip]="getTooltip(result.unit)"
                        [precision]="getPrecision(result.unit)"
                      ></hc-copy-input>
                    </div>
                  }
                </div>
              </div>
            } @else {
              @if (resultLoading$ | ngrxPush) {
                <div
                  class="mt-12 flex w-full flex-1 flex-col place-items-center justify-center"
                >
                  <mat-spinner></mat-spinner>
                </div>
              }
            }
          </div>
          <hc-geometrical-information
            class="flex flex-row"
            [activeConversion]="activeConversion$"
            [inputElement]="defaultInputElement"
          ></hc-geometrical-information>
          <div class="flex flex-row">
            <button
              mat-button
              (click)="onResetButtonClick()"
              color="primary"
              [disabled]="conversionForm.pristine"
            >
              {{ t('reset') }}
            </button>
          </div>
        </div>
      } @else {
        <div
          class="mt-12 flex w-full flex-1 flex-col place-items-center justify-center"
        >
          <mat-spinner></mat-spinner>
        </div>
      }
      @if (conversionResult$ | ngrxPush) {
        <div
          class="mt-4 flex flex-row justify-center align-middle text-caption text-medium-emphasis"
        >
          {{ t('valuesCalculatedWith', { version: (version$ | ngrxPush) }) }}
        </div>
      }
    </div>
  </div>
</div>
