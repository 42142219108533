<div class="bg-surface">
  <schaeffler-app-shell
    [appTitle]="appTitle"
    [appTitleLink]="'/'"
    [hasSidebarLeft]="true"
    [footerLinks]="footerLinks$ | ngrxPush"
    [appVersion]="appVersion"
    [footerFixed]="false"
    [scrollToTop]="true"
  >
    <ng-container sidenavBody>
      <div class="px-5" *transloco="let t; read: 'sidebar'">
        <div
          class="flex mt-6"
          [ngClass]="{
            'items-center flex-row': authService.isLoggedin() | ngrxPush,
            'items-start flex-col': !(authService.isLoggedin() | ngrxPush)
          }"
        >
          @if (authService.isLoggedin() | ngrxPush) {
            @if (authService.getProfilePictureUrl() | ngrxPush) {
              <img
                [src]="authService.getProfilePictureUrl() | ngrxPush"
                width="64"
                class="flex-shrink-0 mr-4"
              />
            } @else {
              <mat-icon
                class="!w-16 !h-16 !text-[64px] text-medium-emphasis flex-shrink-0"
                >account_circle</mat-icon
              >
            }
            <div class="truncate text-h6 w-full">
              {{ authService.getUsername() | ngrxPush }}
            </div>
          } @else {
            <p>{{ t('loginText') }}</p>
            <button
              (click)="authService.login()"
              class="mr-0 mt-2"
              mat-raised-button
              color="primary"
            >
              {{ t('login') }}
            </button>
          }
        </div>
        <div class="py-4">
          <hc-settings-panel></hc-settings-panel>
        </div>
      </div>
    </ng-container>
    <ng-container mainContent>
      <div class="flex h-full min-h-full w-full flex-col">
        <div class="h-full w-full">
          <div class="border-t border-border"></div>
          <router-outlet></router-outlet>
        </div>
        <div
          *transloco="let t; read: 'legal'"
          class="mx-auto w-full max-w-2xl bg-surface"
          id="cookie-settings"
          [ngClass]="{ hidden: !isCookiePage }"
        >
          <div class="py-3 px-4">
            <button id="ot-sdk-btn" class="ot-sdk-show-settings">
              {{ t('cookieSettings') }}
            </button>
          </div>
          <div class="py-3 md:px-4">
            <div id="ot-sdk-cookie-policy"></div>
          </div>
        </div>
      </div>
    </ng-container>
  </schaeffler-app-shell>
</div>
